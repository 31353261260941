<template>
  <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-py-6" style="min-height: 500px;">
    <Table
      :name="name"
      :total="total"
      :fields="fields"
      :items="requests"
      :hasFilter="false"
      :hasSearch="true"
      :perPage="10"
      :isBusy="isBusy"
      @changedPage="setPage"
      @setSearch="setSearch"
    >
      <template #actionsCustom="row">
        <b-button @click.prevent="makeAppointment(row.row)" size="sm" variant="primary" style="font-size: 12px !important; padding-left: 4px !important; padding-right: 4px !important;">
          <span>{{ $t('global.makeAppointment') }}</span>
        </b-button>
      </template>
    </Table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Table from "@/views/components/global/Table";
import {BButton} from 'bootstrap-vue'

export default {
  name: "ContactRequest",
  components: {
    Table, BButton
  },
  data() {
    return {
      loading: false,
      search: ''
    }
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {}
        if (newVal) {
          data.search = newVal
        }
        this.getData(data)
      }
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.getData()
  },
  computed: {
    ...mapGetters({
      requests: 'contactRequest/requests'
    }),
    name() {
      return this.$t('global.contactRequests')
    },
    total() {
      return this.requests.length
    },
    fields() {
      return [
        { key: 'lead', label: this.$t('table.lead'), sortable: true, sortDirection: 'desc', thStyle: { minWidth: '100px !important' } },
        { key: 'project', label: this.$t('table.project'), thStyle: { width: '70px !important' } },
        { key: 'property', label: this.$t('table.property'), thStyle: { width: '70px !important' } },
        { key: 'source', label: this.$t('table.source'), thStyle: { width: '70px !important' } },
        {
          key: 'register_date',
          label: this.$t('table.registerDate'),
          formatter: (value) => {
            return value.split('T')[0]
          },
          thStyle: { minWidth: '100px !important' }
        },
        { key: 'phone', label: this.$t('table.phone'), thStyle: { minWidth: '100px !important' } },
        { key: 'email', label: this.$t('table.email'), thStyle: { minWidth: '100px !important' } },
        { key: 'actions', label: this.$t('table.actions'), class: 'text-center' }
      ]
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data) {
      this.loading = true

      this.$store.dispatch('contactRequest/getRequests', data).finally(() => {
        this.loading = false
      })
    },
    setPage(number) {
      let data = {
        id: this.$route.params.id,
        number
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    setSearch(search) {
      this.search = search
    },
    makeAppointment(row) {
      console.log(row.item, 'ROW')
    }
  }
}
</script>

<style scoped>

</style>